import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectData } from '../redirection';
import { baseVar } from '../Global/GlobalVariable';
import img1 from '../assets/404_plug.svg';
import { Helmet } from 'react-helmet';

function PageRedirection() {
	const [pageRendering, setPageRedirection] = useState(false);

	var currentUrl = window.location.href;

	const navigate = useNavigate();

	useEffect(() => {
		if (currentUrl.includes('.php')) {
			redirectData.map((res) => {
				if (currentUrl == baseVar + res.URL) {
					navigate(res.RecommendedURL);
					setPageRedirection(false);
				} else setPageRedirection(true);
			});
		}
	}, []);

	return (
		<>
			{pageRendering && (
				<>
					{' '}
					<Helmet>
						<meta name='robots' content='noindex,follow' />
						<meta name='author' content='Eauditor Office' />
						<link rel='canonical' href={window.location.href} />
					</Helmet>
					<div className=' mt-5 pt-5'>
						<div className='d-flex mt-lg-5 flex-column justify-content-center text-center align-items-center'>
							<img
								src={img1}
								alt='404'
								width='250'
								height='180'
								title='404'></img>
							<p className='mt-lg-5 mt-4 error_content'>
								Error 404 <br></br>We can’t seem to find the <br></br> page you
								are looking for
							</p>{' '}
							<p className='mt-lg-5 mt-4 error_subContent'>
								Herare are some helpful links instead
							</p>
							<div className='mt-2 d-flex'>
								<a title='HOME' className='redirection ' href={baseVar}>
									HOME
								</a>
								<a
									title='CONTACT US'
									className='redirection mx-4'
									href={baseVar + '/contact-us'}>
									CONTACT US
								</a>
								<a
									title='BLOG'
									className='redirection'
									href={baseVar + '/blog'}>
									BLOG
								</a>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default PageRedirection;
