import { AuthAxios } from '../Global/GlobalVariable';

var serviceData;

export const serviceList = async () => {
	let response = await AuthAxios.get(`/GetServicesList`);

	if (response) {
		return response;
	}
};

export const serviceContentList = async ({ id }) => {
	try {
		let response = await AuthAxios.post(`/GetPageContent`, {
			url: id,
		});

		serviceData = response.data;

		if (response.status == 200) {
			return response;
		}
	} catch (err) {
		window.location.href = '/404';
	}
};

export const recentBlog = async () => {
	try {
		let response = await AuthAxios.get(`/blogRecent`);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const blogList = async ({ count }) => {
	try {
		let response = await AuthAxios.get(`/blogList?page=${count}&recent=1`);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const blogContent = async ({ id }) => {
	try {
		let response = await AuthAxios.get(`/blogService?url=${id}`);
		serviceData = response.data;
		if (response.data.length === 0) {
			window.location.href = '/404';
		} else {
			return response;
		}
	} catch (err) {
		window.location.href = '/404';
	}
};

export const relatedBlog = async ({ id }, count) => {
	try {
		let response = await AuthAxios.get(`/blogRelated/?url=${id}&page=${count}`);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const metaTagBlog = async ({ id }) => {
	try {
		let response = await AuthAxios.get(`/blogServiceMeta/?url=${id}`);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const blogListnewD = async () => {
	try {
		let response = await AuthAxios.get(`/blogList?limit=3`);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const metaTag = async (id) => {
	var urlEndPoint = window.location.href.split('/');

	if (urlEndPoint[urlEndPoint.length - 1] === '') {
		id = 'home';
		var response = await AuthAxios.post(`/GetMetaData`, { url: id });
	} else if (urlEndPoint[urlEndPoint.length - 1] === 'contact-us') {
		id = 'contact-us';
		var response = await AuthAxios.post(`/GetMetaData`, { url: id });
	} else if (urlEndPoint[urlEndPoint.length - 1] === 'privacy-policy') {
		id = 'privacy-policy';
		var response = await AuthAxios.post(`/GetMetaData`, { url: id });
	} else if (urlEndPoint[urlEndPoint.length - 1] === 'about-us') {
		id = 'about-us';
		var response = await AuthAxios.post(`/GetMetaData`, { url: id });
	} else if (urlEndPoint[urlEndPoint.length - 1] === 'sitemap.html') {
		id = 'sitemap.html';
		var response = await AuthAxios.post(`/GetMetaData`, { url: id });
	} else if (urlEndPoint[urlEndPoint.length - 1] === 'blog') {
		id = 'blog';
		var response = await AuthAxios.post(`/GetMetaData`, { url: id });
	}

	if (response.data.error) {
		return serviceData.seo_details;
	} else {
		return response.data;
	}
};

export const blogCommon = async (CategoriesBase, getCategoryName) => {
	let categoryName;
	if (getCategoryName === undefined) {
		categoryName = '';
	} else {
		categoryName = getCategoryName;
	}
	try {
		let response = await AuthAxios.get(CategoriesBase + categoryName);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const recentArticles = async (recentArticlesBase, no) => {
	try {
		let response = await AuthAxios.get(
			`${recentArticlesBase}?limit=3&from=${no}`
		);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const categoryList = async (recentValue, categoryCount) => {
	try {
		let response = await AuthAxios.get(
			`/blogList?page=${categoryCount}&recent=1${recentValue}`
		);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const previousNext = async ({ id }) => {
	try {
		let response = await AuthAxios.get(`/blogServiceDirect?url=${id}`);
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};

export const newsLetterApi = async (emailID) => {
	try {
		let response = await AuthAxios.post(`/saveNewsLetter`, { email: emailID });
		serviceData = response.data;
		if (response.status == 200) {
			return response;
		}
	} catch (err) {}
};
