import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoutes from './ProtectedRoutes';
import { serviceList, metaTagBlog, blogListnewD } from '../axios/axios';
import { metaTag } from '../axios/axios';
import { Helmet } from 'react-helmet';
import { dataOne } from '../staticMenu';
import PageRedirection from './PageRedirection';
import { Suspense } from 'react';

const Navbars = React.lazy(() => import('../components/Navbar/Navbar'));
const Footer = React.lazy(() => import('../components/Footer/Footer'));
const Home = React.lazy(() => import('../Screens/Home/Home'));
const Service = React.lazy(() => import('../Screens/service/Service'));
const Contact = React.lazy(() => import('../Screens/contact/Contact'));
const PrivacyPolicy = React.lazy(() =>
	import('../Screens/privacyPolicy/PrivacyPolicy')
);
const Blog = React.lazy(() => import('../Screens/blog/Blog'));
const BlogDetails = React.lazy(() => import('../Screens/blog/BlogDetails'));
const Sitemap = React.lazy(() => import('../Screens/sitemap/Sitemap'));
const AboutUs = React.lazy(() => import('../Screens/aboutUs/AboutUs'));
const CategoryList = React.lazy(() => import('../Screens/blog/CategoryList'));

function Router() {
	const [dataList, setDataList] = useState(dataOne);
	const [meta, setMeta] = useState();
	const [blogMeta, setBlogMeta] = useState([]);
	const [blogData, setBlogData] = useState([]);

	var urlEndPoint = window.location.href.split('/');

	var id = urlEndPoint[urlEndPoint.length - 1];

	const data = async () => {
		await serviceList().then((res) => {
			setDataList(res.data);
		});
		try {
			await metaTag(id).then((res) => {
				setMeta(res);
			});
			if (id === '' || id === 'home') {
				await blogListnewD().then((res) => setBlogData(res.data));
			}
		} catch (err) {
			if (id) {
				await metaTagBlog({ id }).then((res) => setBlogMeta(res.data));
			}
		}
	};

	useEffect(() => {
		window.onload = () => {
			data();
		};
	}, [id]);

	let routes = (
		<BrowserRouter>
			<Navbars data={dataList} />
			{blogMeta && (
				<Helmet>
					<meta name='robots' content='index, follow' />
					<title>{blogMeta.service_title}</title>
					<meta name='description' content={blogMeta.meta_desc} />
					<meta name='author' content='Eauditor Office' />
					<meta property='og:title' content={blogMeta.service_title} />
					<meta property='og:description' content={blogMeta.meta_desc} />
					<meta property='og:image' content={blogMeta.ogImagePath} />
					<link rel='canonical' href={window.location.href} />
				</Helmet>
			)}
			{meta && (
				<Helmet>
					<meta name='robots' content='index, follow' />
					<title>{meta.service_title}</title>
					<meta name='description' content={meta.meta_desc} />
					<meta name='author' content='Eauditor Office' />
					<meta property='og:title' content={meta.service_title} />
					<meta property='og:description' content={meta.meta_desc} />
					<meta property='og:image' content={meta.ogImagePath} />
					<link rel='canonical' href={window.location.href} />
				</Helmet>
			)}
			<ToastContainer />
			<Routes>
				<Route path='/' element={<Home data={blogData} />} />
				<Route path='/contact-us' element={<Contact />} />
				<Route path=':id' element={<Service />} />
				<Route path='*' element={<PageRedirection />} />
				<Route path='/404' element={<ProtectedRoutes />} />
				<Route path='/privacy-policy' element={<PrivacyPolicy />} />
				<Route path='/about-us' element={<AboutUs />} />
				<Route path='/blog' element={<Blog />} />{' '}
				<Route path='/blog/:id' element={<BlogDetails />} />
				<Route path='/sitemap.html' element={<Sitemap />} />
				<Route path='/blog/category/:id' element={<CategoryList />} />
			</Routes>
			<Footer data={dataList} />
		</BrowserRouter>
	);

	return (
		<>
			<Suspense
				fallback={
					<h1 className='justify-content-center align-items-center vh-100 d-flex'>
						Loading...
					</h1>
				}>
				{routes}
			</Suspense>
		</>
	);
}

export default Router;
