import './App.css';
import Router from './routes/Router';

function App() {
	return (
		<>
			<div className='App'>
				<Router />
			</div>
		</>
	);
}

export default App;
