export const dataOne = [
	{
		id: 3,
		user_id: '17',
		service_head_name: 'New Business',
		subservice_app: '1',
		active: '1',
		viewMenu: '1',
		service_list_group: [
			{
				id: 1,
				service_Head_id: '3',
				service_group: 'Business Registration',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 2,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Private Limited Company Registration',
						active: '1',
						updated_at: '2023-01-09T12:37:18.353000Z',
						service_url: 'private-limited-company-registration',
						service_pdf:
							'B) Document Requirement List & Procedure PVT.pdf,C) Quote- Pvt. Ltd. Registration.pdf,D) Advantages of Private Limited Registration.pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: 'private-limited-company',
						PageRedirect: '0',
						service_list_group: '1',
						tags: 'register, company, ltd, pvt',
					},
					{
						id: 5,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Limited Liability Partnership (LLP)',
						active: '1',
						updated_at: '2022-08-02T04:26:08.647000Z',
						service_url: 'limited-liability-partnership-registration',
						service_pdf: 'Limited Liability Partnership(LLP) Registration.pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '1',
						tags: null,
					},
					{
						id: 22,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Partnership Firm Registration',
						active: '1',
						updated_at: '2022-10-04T11:05:16.537000Z',
						service_url: 'partnership-firm-registration',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '5',
						subCateId: '9',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '1',
						tags: null,
					},
					{
						id: 40,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Public Limited Company Registration',
						active: '1',
						updated_at: '2022-10-08T10:52:41.900000Z',
						service_url: 'public-limited-company-registration',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '1',
						tags: null,
					},
					{
						id: 42,
						service_head_id: '3',
						user_id: '17',
						service_name: 'One Person Company Registration',
						active: '1',
						updated_at: '2022-08-02T04:27:01.047000Z',
						service_url: 'one-person-company-registration',
						service_pdf: 'One Person Company Registration.pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '1',
						tags: null,
					},
					{
						id: 90,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Startup Business Registration',
						active: '1',
						updated_at: '2022-09-20T05:27:32.793000Z',
						service_url: 'startup-business-registration',
						service_pdf: 'New Microsoft Word Document (2).docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url:
							'https://www.eauditoroffice.com/services/business-plan-preparation/',
						PageRedirect: '0',
						service_list_group: '1',
						tags: null,
					},
					{
						id: 94,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Sole Proprietorship Registration',
						active: '1',
						updated_at: '2022-10-12T12:06:00.707000Z',
						service_url: 'sole-proprietorship-registration',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '2',
						subCateId: '2',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '1',
						tags: null,
					},
				],
			},
			{
				id: 2,
				service_Head_id: '3',
				service_group: 'Special Entities',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 43,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Producer Company',
						active: '1',
						updated_at: '2022-08-02T04:31:55.540000Z',
						service_url: 'producer-company-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '2',
						tags: null,
					},
					{
						id: 44,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Indian subsidiary Registration',
						active: '1',
						updated_at: '2022-08-02T04:32:09.317000Z',
						service_url: 'indian-subsidiary-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '2',
						tags: null,
					},
					{
						id: 45,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Branch Office Registration',
						active: '1',
						updated_at: '2022-10-08T10:53:11.200000Z',
						service_url: 'branch-office-registration',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '2',
						tags: null,
					},
					{
						id: 46,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Liaison office Registration',
						active: '1',
						updated_at: '2022-10-08T10:53:43.053000Z',
						service_url: 'liaison-office-registration',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '2',
						tags: null,
					},
					{
						id: 78,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Foreign Subsidiary Incorporation',
						active: '1',
						updated_at: '2022-08-02T04:33:55.593000Z',
						service_url: 'foreign-subsidiary-incorporation-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '2',
						tags: null,
					},
				],
			},
			{
				id: 3,
				service_Head_id: '3',
				service_group: 'NGO',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 39,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Section 8 Company Registration',
						active: '1',
						updated_at: '2022-10-08T10:52:01.543000Z',
						service_url: 'section-8-company-registration',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: 'Section_8_Company_Registration',
						PageRedirect: '0',
						service_list_group: '3',
						tags: null,
					},
					{
						id: 83,
						service_head_id: '3',
						user_id: '17',
						service_name: '80G & 12AA Registration',
						active: '1',
						updated_at: '2022-08-02T04:34:56.833000Z',
						service_url: '80g-12aa-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '3',
						tags: null,
					},
					{
						id: 103,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Society Registration',
						active: '1',
						updated_at: '2022-08-02T04:35:29.670000Z',
						service_url: 'society-registration',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '2',
						subCateId: '2',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '3',
						tags: null,
					},
					{
						id: 104,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Trust Registration',
						active: '1',
						updated_at: '2022-08-02T04:34:42.863000Z',
						service_url: 'trust-registration',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '3',
						tags: null,
					},
					{
						id: 106,
						service_head_id: '3',
						user_id: '17',
						service_name: 'FCRA Registration',
						active: '1',
						updated_at: '2022-08-02T04:35:13.787000Z',
						service_url: 'fcra-registration',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '2',
						subCateId: '2',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '3',
						tags: null,
					},
				],
			},
			{
				id: 4,
				service_Head_id: '3',
				service_group: 'Government Registration',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 75,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Import/Export(IE) Code Registration',
						active: '1',
						updated_at: '2022-09-20T05:21:23.347000Z',
						service_url: 'import-export-code-iec-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '7',
						subCateId: '14',
						redirct_url: 'services/import-export-ie-code',
						PageRedirect: '0',
						service_list_group: '4',
						tags: null,
					},
					{
						id: 86,
						service_head_id: '3',
						user_id: '17',
						service_name: 'Udyam Registration / Udyog Aadhar(MSME/SSI)',
						active: '1',
						updated_at: '2022-08-02T04:35:57.723000Z',
						service_url: 'udyam-registration-online',
						service_pdf: 'New Microsoft Word Document (2).docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: 'udyam-registration',
						PageRedirect: '0',
						service_list_group: '4',
						tags: null,
					},
					{
						id: 105,
						service_head_id: '3',
						user_id: '17',
						service_name: 'startup india registration',
						active: '1',
						updated_at: '2022-08-02T06:46:27.373000Z',
						service_url: 'startup-india-registration',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '2',
						subCateId: '2',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '4',
						tags: null,
					},
				],
			},
			{
				id: 5,
				service_Head_id: '3',
				service_group: 'Licence',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 66,
						service_head_id: '3',
						user_id: '17',
						service_name: 'EPF Registration',
						active: '1',
						updated_at: '2022-09-28T10:10:04.653000Z',
						service_url: 'epf-registration-online',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '5',
						tags: null,
					},
					{
						id: 67,
						service_head_id: '3',
						user_id: '17',
						service_name: 'ESIC Registration',
						active: '1',
						updated_at: '2022-08-02T04:37:13.813000Z',
						service_url: 'esic-registration-online',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '5',
						tags: null,
					},
					{
						id: 73,
						service_head_id: '3',
						user_id: '17',
						service_name: 'FSSAI Licence',
						active: '1',
						updated_at: '2022-08-02T04:37:26.560000Z',
						service_url: 'fssai-licence-registeration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '5',
						tags: null,
					},
				],
			},
		],
	},
	{
		id: 5,
		user_id: '17',
		service_head_name: 'Manage Your Business',
		subservice_app: '1',
		active: '1',
		viewMenu: '1',
		service_list_group: [
			{
				id: 6,
				service_Head_id: '5',
				service_group: 'Company Changes & return',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 4,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Change Company Address',
						active: '1',
						updated_at: '2022-10-04T11:02:07.553000Z',
						service_url: 'change-registered-office-address',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '16',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 9,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Share Transfer of a Company',
						active: '1',
						updated_at: '2022-10-04T11:02:31.507000Z',
						service_url: 'company-share-transfer',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 52,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Removal/Resignation of Director',
						active: '1',
						updated_at: '2022-09-23T12:37:17.567000Z',
						service_url: 'removal-and-resignation-of-director',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '16',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 53,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Increase Authorised Share Capital',
						active: '1',
						updated_at: '2022-08-02T04:40:38.923000Z',
						service_url: 'increase-authorised-share-capital',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 54,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Company Name Change',
						active: '1',
						updated_at: '2022-08-02T04:40:54.813000Z',
						service_url: 'company-name-change',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 55,
						service_head_id: '5',
						user_id: '17',
						service_name: 'LLP Name Change',
						active: '1',
						updated_at: '2022-08-02T04:41:08.233000Z',
						service_url: 'llp-name-change',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 56,
						service_head_id: '5',
						user_id: '17',
						service_name: 'MOA & AOA Amendments',
						active: '1',
						updated_at: '2022-08-02T04:41:36.810000Z',
						service_url: 'moa-aoa-ammendments',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 62,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Appointment of Auditors',
						active: '1',
						updated_at: '2022-08-02T04:41:49.220000Z',
						service_url: 'appointment-of-auditors',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 81,
						service_head_id: '5',
						user_id: '17',
						service_name: 'DIN Surrender',
						active: '1',
						updated_at: '2022-08-02T04:45:33.467000Z',
						service_url: 'din-surrender',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 82,
						service_head_id: '5',
						user_id: '17',
						service_name: 'DIN Validation (DIR-3 KYC)',
						active: '1',
						updated_at: '2022-08-02T04:42:02.603000Z',
						service_url: 'din-validation',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 91,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Update your DIN',
						active: '1',
						updated_at: '2022-08-02T04:45:17.447000Z',
						service_url: 'update-din-details',
						service_pdf: 'New Microsoft Word Document (2).docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
					{
						id: 95,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Add/Appointment of Director',
						active: '1',
						updated_at: '2022-08-02T04:39:56.757000Z',
						service_url: 'appointment-of-director',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '16',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '6',
						tags: null,
					},
				],
			},
			{
				id: 7,
				service_Head_id: '5',
				service_group: 'Mandatory Annual Filings',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 24,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Private limited Company RoC Compliance',
						active: '1',
						updated_at: '2022-10-14T12:38:46.163000Z',
						service_url: 'private-limited-roc-filing',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: 'private-limited-roc-compliance',
						PageRedirect: '0',
						service_list_group: '7',
						tags: null,
					},
					{
						id: 25,
						service_head_id: '5',
						user_id: '17',
						service_name: 'OPC RoC Compliance',
						active: '1',
						updated_at: '2022-10-04T11:06:03.267000Z',
						service_url: 'one-person-company-roc-compliance',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '7',
						tags: null,
					},
					{
						id: 27,
						service_head_id: '5',
						user_id: '17',
						service_name: 'LLP RoC Compliance',
						active: '1',
						updated_at: '2022-10-04T11:06:23.633000Z',
						service_url: 'llp-roc-compliance',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '7',
						tags: null,
					},
				],
			},
			{
				id: 8,
				service_Head_id: '5',
				service_group: 'Accounting & Payroll',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 63,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Bookkeeping & Accounting',
						active: '1',
						updated_at: '2022-08-24T23:48:22.987000Z',
						service_url: 'bookkeeping-accounting-services',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url:
							'https://www.eauditoroffice.com/blog/bookkeeping-accounting-services',
						PageRedirect: '0',
						service_list_group: '8',
						tags: null,
					},
					{
						id: 70,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Payroll Services',
						active: '1',
						updated_at: '2022-08-02T04:47:19.277000Z',
						service_url: 'payroll-services',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '8',
						tags: null,
					},
				],
			},
			{
				id: 9,
				service_Head_id: '5',
				service_group: 'Changes in Firm',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 61,
						service_head_id: '5',
						user_id: '17',
						service_name:
							'Change in Partners (Appointment or removal of partners)',
						active: '1',
						updated_at: '2022-08-02T04:48:08.447000Z',
						service_url: 'change-in-partners',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '2',
						subCateId: '2',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '9',
						tags: null,
					},
				],
			},
			{
				id: 10,
				service_Head_id: '5',
				service_group: 'Closure of Business',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 57,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Closure of Pvt Ltd',
						active: '1',
						updated_at: '2022-08-02T04:48:22.460000Z',
						service_url: 'closing-private-limited-company',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '16',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '10',
						tags: null,
					},
					{
						id: 58,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Closure of OPC',
						active: '1',
						updated_at: '2022-08-02T04:48:35.117000Z',
						service_url: 'closing-one-person-company',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '3',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '10',
						tags: null,
					},
					{
						id: 59,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Closure of LLP',
						active: '1',
						updated_at: '2022-08-02T04:48:55.660000Z',
						service_url: 'closing-limited-liability-partnership-company',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '3',
						subCateId: '16',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '10',
						tags: null,
					},
					{
						id: 60,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Dissolution of Partnership Firm',
						active: '1',
						updated_at: '2022-08-02T04:49:17.170000Z',
						service_url: 'dissolution-of-partnership-firm',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '5',
						subCateId: '9',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '10',
						tags: null,
					},
				],
			},
			{
				id: 11,
				service_Head_id: '5',
				service_group: 'IE Code Services',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 76,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Import/Export(IE) Code Modification',
						active: '1',
						updated_at: '2022-08-02T04:49:33.537000Z',
						service_url: 'import-export-code-iec-modification',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '7',
						subCateId: '15',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '11',
						tags: null,
					},
					{
						id: 77,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Import/Export(IE) Code Renewal',
						active: '1',
						updated_at: '2022-09-20T05:21:31.907000Z',
						service_url: 'import-export-code-iec-renewal',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '7',
						subCateId: '14',
						redirct_url: 'services/import-export-ie-code',
						PageRedirect: '0',
						service_list_group: '11',
						tags: null,
					},
				],
			},
			{
				id: 12,
				service_Head_id: '5',
				service_group: 'Filling & Renewal',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 68,
						service_head_id: '5',
						user_id: '17',
						service_name: 'PF Return',
						active: '1',
						updated_at: '2022-08-02T04:50:13.573000Z',
						service_url: 'pf-return-filing',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '12',
						tags: null,
					},
					{
						id: 69,
						service_head_id: '5',
						user_id: '17',
						service_name: 'ESI Return',
						active: '1',
						updated_at: '2022-08-02T04:50:26.780000Z',
						service_url: 'esi-return-filing',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '12',
						tags: null,
					},
					{
						id: 74,
						service_head_id: '5',
						user_id: '17',
						service_name: 'FSSAI Renewal',
						active: '1',
						updated_at: '2022-08-02T04:50:42.227000Z',
						service_url: 'fssai-renewal',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '12',
						tags: null,
					},
					{
						id: 84,
						service_head_id: '5',
						user_id: '17',
						service_name: 'Digital Keys (DSC)',
						active: '1',
						updated_at: '2022-08-02T04:51:26.417000Z',
						service_url: 'digital-signature-certificate',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '12',
						tags: null,
					},
				],
			},
		],
	},
	{
		id: 6,
		user_id: '17',
		service_head_name: 'GST',
		subservice_app: '1',
		active: '1',
		viewMenu: '1',
		service_list_group: [
			{
				id: 13,
				service_Head_id: '6',
				service_group: 'GST Services',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 10,
						service_head_id: '6',
						user_id: '17',
						service_name: 'Letter of Undertaking (LUT) in GST',
						active: '1',
						updated_at: '2022-10-04T11:02:56.590000Z',
						service_url: 'gst-lut-filing',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '6',
						subCateId: '7',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
					{
						id: 12,
						service_head_id: '6',
						user_id: '17',
						service_name: 'GST Refund',
						active: '1',
						updated_at: '2022-10-04T11:03:20.097000Z',
						service_url: 'gst-refund',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '6',
						subCateId: '13',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
					{
						id: 13,
						service_head_id: '6',
						user_id: '17',
						service_name: 'GST Registration',
						active: '1',
						updated_at: '2022-09-20T05:34:26.340000Z',
						service_url: 'gst-registration',
						service_pdf: 'GST Registration.pdf',
						LocId: '2',
						CateId: '2',
						subCateId: '2',
						redirct_url: 'gst-registration.php/gst-return-filing',
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
					{
						id: 47,
						service_head_id: '6',
						user_id: '17',
						service_name: 'GST Annual Return',
						active: '1',
						updated_at: '2022-08-02T04:53:10.567000Z',
						service_url: 'gst-annual-return',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '6',
						subCateId: '7',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
					{
						id: 48,
						service_head_id: '6',
						user_id: '17',
						service_name: 'GST E-Way Bill',
						active: '1',
						updated_at: '2022-10-14T12:36:41.153000Z',
						service_url: 'gst-eway-bill-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '6',
						subCateId: '7',
						redirct_url: 'gst-eway-bill',
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
					{
						id: 49,
						service_head_id: '6',
						user_id: '17',
						service_name: 'GST Registration Cancellation/Surrender',
						active: '1',
						updated_at: '2022-08-02T04:53:49.347000Z',
						service_url: 'cancel-surrender-gst-registration',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '6',
						subCateId: '13',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
					{
						id: 50,
						service_head_id: '6',
						user_id: '17',
						service_name: 'GST Modification',
						active: '1',
						updated_at: '2022-08-02T05:17:57.653000Z',
						service_url: 'gst-modification',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '6',
						subCateId: '13',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '13',
						tags: null,
					},
				],
			},
		],
	},
	{
		id: 7,
		user_id: '17',
		service_head_name: 'Grow Your Business',
		subservice_app: '1',
		active: '1',
		viewMenu: '1',
		service_list_group: [
			{
				id: 16,
				service_Head_id: '7',
				service_group: 'Business Development',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 19,
						service_head_id: '7',
						user_id: '17',
						service_name: 'Virtual CFO Services',
						active: '1',
						updated_at: '2022-10-04T11:04:55.427000Z',
						service_url: 'virtual-cfo-services',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '16',
						tags: null,
					},
					{
						id: 64,
						service_head_id: '7',
						user_id: '17',
						service_name: 'CMA Report',
						active: '1',
						updated_at: '2022-08-02T05:20:38.940000Z',
						service_url: 'cma-report-preparation',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '16',
						tags: null,
					},
					{
						id: 65,
						service_head_id: '7',
						user_id: '17',
						service_name: 'Business Plan Preparation',
						active: '1',
						updated_at: '2022-08-02T05:20:53.313000Z',
						service_url: 'business-plan-preparation',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '10',
						subCateId: '11',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '16',
						tags: null,
					},
					{
						id: 93,
						service_head_id: '7',
						user_id: '17',
						service_name: 'IND - AS',
						active: '1',
						updated_at: '2022-08-02T05:21:05.657000Z',
						service_url: 'indian-accounting-standards',
						service_pdf: 'New Microsoft Word Document (2).docx',
						LocId: '2',
						CateId: '10',
						subCateId: '5',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '16',
						tags: null,
					},
				],
			},
			{
				id: 17,
				service_Head_id: '7',
				service_group: 'FDI Compliance',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 17,
						service_head_id: '7',
						user_id: '17',
						service_name: 'FEMA & FDI Compliance',
						active: '1',
						updated_at: '2022-10-14T12:39:17.617000Z',
						service_url: 'fdi-compliance',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '8',
						subCateId: '4',
						redirct_url: 'fema-fdi-compliance',
						PageRedirect: '0',
						service_list_group: '17',
						tags: null,
					},
					{
						id: 18,
						service_head_id: '7',
						user_id: '17',
						service_name: 'External Commercial Borrowings (ECB) Compliance',
						active: '1',
						updated_at: '2022-10-04T11:04:34.020000Z',
						service_url: 'external-commercial-borrowings-ecb-compliance',
						service_pdf: 'eAuditor Office - Expert Support (1).pdf',
						LocId: '2',
						CateId: '8',
						subCateId: '4',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '17',
						tags: null,
					},
					{
						id: 96,
						service_head_id: '7',
						user_id: '17',
						service_name: 'FCGPR Reporting - FDI Compliance',
						active: '1',
						updated_at: '2022-08-02T05:24:23.283000Z',
						service_url: 'fcgpr-reporting',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '8',
						subCateId: '4',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '17',
						tags: null,
					},
					{
						id: 109,
						service_head_id: '7',
						user_id: '17',
						service_name: 'RBI Compliance - FDI Compliance',
						active: '1',
						updated_at: '2022-08-02T05:23:51.797000Z',
						service_url: 'rbi-compliance',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '8',
						subCateId: '4',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '17',
						tags: null,
					},
				],
			},
			{
				id: 18,
				service_Head_id: '7',
				service_group: 'Merger/Acquisition',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 92,
						service_head_id: '7',
						user_id: '17',
						service_name: 'Valuation - Merger/Acquisition',
						active: '1',
						updated_at: '2022-08-02T05:21:54.367000Z',
						service_url: 'merger-acquisition-valuation',
						service_pdf: 'New Microsoft Word Document (2).docx',
						LocId: '2',
						CateId: '9',
						subCateId: '8',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '18',
						tags: null,
					},
					{
						id: 98,
						service_head_id: '7',
						user_id: '17',
						service_name: 'Taxation - Merger/Acquisition',
						active: '1',
						updated_at: '2022-08-02T05:22:07.627000Z',
						service_url: 'merger-acquisition-taxation',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '9',
						subCateId: '8',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '18',
						tags: null,
					},
				],
			},
		],
	},
	{
		id: 13,
		user_id: '17',
		service_head_name: 'Income Tax',
		subservice_app: '1',
		active: '1',
		viewMenu: '1',
		service_list_group: [
			{
				id: 14,
				service_Head_id: '13',
				service_group: 'IncomeTax Services',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 51,
						service_head_id: '6',
						user_id: '17',
						service_name: 'Income Tax Returns Filings',
						active: '1',
						updated_at: '2022-08-02T05:18:54.287000Z',
						service_url: 'income-tax-returns-filing',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '14',
						tags: null,
					},
					{
						id: 85,
						service_head_id: '6',
						user_id: '17',
						service_name: 'Form 15CA & Form 15CB',
						active: '1',
						updated_at: '2022-10-14T12:37:18.003000Z',
						service_url: 'form-15ca-and-15cb-filing',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: 'form-15ca-and-15cb',
						PageRedirect: '0',
						service_list_group: '14',
						tags: null,
					},
					{
						id: 89,
						service_head_id: '6',
						user_id: '17',
						service_name: 'NRI Income Tax  Planning & Filing',
						active: '1',
						updated_at: '2022-10-14T12:37:59.177000Z',
						service_url: 'nri-income-tax-filing',
						service_pdf: 'New Microsoft Word Document (2).docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: 'nri-income-tax-planning',
						PageRedirect: '0',
						service_list_group: '14',
						tags: null,
					},
					{
						id: 99,
						service_head_id: '6',
						user_id: '17',
						service_name: 'Tax Deducted at Source',
						active: '1',
						updated_at: '2022-10-14T12:38:20.567000Z',
						service_url: 'tds-filing',
						service_pdf: 'New Microsoft Word Document.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: 'tax-deducted-at-source-tds',
						PageRedirect: '0',
						service_list_group: '14',
						tags: null,
					},
				],
			},
			{
				id: 15,
				service_Head_id: '13',
				service_group: 'NSDL Services',
				user_id: '17',
				active: '1',
				submenu: [
					{
						id: 71,
						service_head_id: '6',
						user_id: '17',
						service_name: 'PAN Application',
						active: '1',
						updated_at: '2022-08-02T05:20:01.933000Z',
						service_url: 'apply-for-pan-online',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '15',
						tags: null,
					},
					{
						id: 72,
						service_head_id: '6',
						user_id: '17',
						service_name: 'TAN Application',
						active: '1',
						updated_at: '2022-08-02T05:19:49.820000Z',
						service_url: 'apply-for-tan-online',
						service_pdf: 'EAO.docx',
						LocId: '2',
						CateId: '4',
						subCateId: '6',
						redirct_url: null,
						PageRedirect: '0',
						service_list_group: '15',
						tags: null,
					},
				],
			},
		],
	},
];
