import React from 'react';
import './protectedRouters.css';
import img1 from '../assets/404_plug.svg';
import { baseVar } from '../Global/GlobalVariable';
import { Helmet } from 'react-helmet';

function ProtectedRoutes() {
	return (
		<>
			{' '}
			<Helmet>
				<meta name='robots' content='noindex,follow' />
				<meta name='author' content='Eauditor Office' />
				<link rel='canonical' href={window.location.href} />
			</Helmet>
			<div className=' mt-5 pt-5'>
				<div className='d-flex mt-lg-5 flex-column justify-content-center text-center align-items-center'>
					<img src={img1} alt='404' width='250' height='180' title='404'></img>
					<p className='mt-lg-5 mt-4 error_content'>
						Error 404 <br></br>We can’t seem to find the <br></br> page you are
						looking for
					</p>{' '}
					<p className='mt-lg-5 mt-4 error_subContent'>
						Herare are some helpful links instead
					</p>
					<div className='mt-2 d-flex'>
						<a title='HOME' className='redirection ' href={baseVar}>
							HOME
						</a>
						<a
							title='CONTACT US'
							className='redirection mx-4'
							href={baseVar + '/contact-us'}>
							CONTACT US
						</a>
						<a title='BLOG' className='redirection' href={baseVar + '/blog'}>
							BLOG
						</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProtectedRoutes;
