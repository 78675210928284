export const redirectData = [
	{
		URL: '/login.php/about-us',
		RecommendedURL: '/about-us',
	},
	{
		URL: '/contact-us.php/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/partnership-firm-registration.php',
		RecommendedURL: '/partnership-firm-registration',
	},
	{
		URL: '/virtual-cfo-services.php/private-limited-roc-compliance',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/change-registered-office-address.php',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/startup-india-registration.php/import/private-limited-company-roc-compliance',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/index.php/services/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/dashboard.php/import/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/business-plan-preparation',
		RecommendedURL: '/business-plan-preparation',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/gst-modification',
		RecommendedURL: '/gst-modification',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/increase-authorised-share-capital',
		RecommendedURL: '/increase-authorised-share-capital',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/gst-refund',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/change-registered-office-address.php/company-share-transfer',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/change-registered-office-address.php/gst-refund',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/startup-india-registration.php/fcgpr-reporting',
		RecommendedURL: '/fcgpr-reporting',
	},
	{
		URL: '/startup-india-registration.php/Section_8_Company_Registration',
		RecommendedURL: '/section-8-company-registration',
	},
	{
		URL: '/dashboard.php/merger-acquisition-taxation',
		RecommendedURL: '/merger-acquisition-taxation',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/udyam-registration-online',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/nri-income-tax-planning',
		RecommendedURL: '/nri-income-tax-filing',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/import/privacy-policy',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/one-person-company-roc-compliance',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/private-limited-company-roc-compliance',
		RecommendedURL: '/',
	},
	{
		URL: '/change-registered-office-address.php/one-person-company-roc-compliance',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/dashboard.php/apply-for-tan-online',
		RecommendedURL: '/apply-for-tan-online',
	},
	{
		URL: '/dashboard.php/sole-proprietorship-registraion',
		RecommendedURL: '/sole-proprietorship-registration',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/din-validation',
		RecommendedURL: '/din-validation',
	},
	{
		URL: ' /rbi-compliance-fdi-compliance.php/llp-name-change',
		RecommendedURL: '/llp-name-change',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/fssai-licence-registeration',
		RecommendedURL: '/fssai-licence-registeration',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/esic-registration-online',
		RecommendedURL: '/esic-registration-online',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/change-in-partners',
		RecommendedURL: '/change-in-partners',
	},
	{
		URL: '/virtual-cfo-services.php/import/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/virtual-cfo-services.php/dissolution-of-partnership-firm',
		RecommendedURL: '/dissolution-of-partnership-firm',
	},
	{
		URL: '/index.php/import/about-us',
		RecommendedURL: '/',
	},
	{
		URL: '/virtual-cfo-services.php/form-15ca-and-15cb',
		RecommendedURL: '/form-15ca-and-15cb-filing',
	},
	{
		URL: '/virtual-cfo-services.php/import/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/enquiry-success',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/fcra-registration',
		RecommendedURL: '/fcra-registration',
	},
	{
		URL: '/contact-us.php/import/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/contact-us.php/import/about-us',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/import/privacy-policy',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/enquiry-success',
		RecommendedURL: '/',
	},
	{
		URL: '/partnership-firm-registration.php/private-limited-company-roc-compliance',
		RecommendedURL: '/partnership-firm-registration',
	},
	{
		URL: '/startup-india-registration.php/import/privacy-policy',
		RecommendedURL: '/privacy-policy',
	},
	{
		URL: '/startup-india-registration.php/import/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/contact-us.php/import/import-export-code-iec-renewal',
		RecommendedURL: '/import-export-code-iec-renewal',
	},
	{
		URL: '/dashboard.php/digital-signature-certificate',
		RecommendedURL: '/digital-signature-certificate',
	},
	{
		URL: '/virtual-cfo-services.php/enquiry-success',
		RecommendedURL: '/',
	},
	{
		URL: '/limited-liability-partnership-llp.php/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/startup-india-registration.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/closing-limited-liability-partnership-company',
	},
	{
		URL: '/change-registered-office-address.php/gst-lut-filing',
		RecommendedURL: '/gst-lut-filing',
	},
	{
		URL: '/change-registered-office-address.php/private-limited-roc-compliance',
		RecommendedURL: '/private-limited-roc-filing',
	},
	{
		URL: '/index.php/services/privacy-policy',
		RecommendedURL: '',
	},
	{
		URL: '/startup-india-registration.php/fssai-renewal',
		RecommendedURL: '/fssai-renewal',
	},
	{
		URL: '/change-registered-office-address.php/change-registered-office-address',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/change-registered-office-address.php/partnership-firm-registration',
		RecommendedURL: '/partnership-firm-registration',
	},
	{
		URL: '/virtual-cfo-services.php/import/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/change-registered-office-address.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/fcra-registration',
		RecommendedURL: '/fcra-registration',
	},
	{
		URL: '/change-registered-office-address.php/login',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/share-transfer-of-a-company.php/fdi-compliance',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/dashboard.php/import-export-code-iec-modification',
		RecommendedURL: '/import-export-code-iec-modification',
	},
	{
		URL: '/dashboard.php/80g-12aa-registration',
		RecommendedURL: '/80g-12aa-registration',
	},
	{
		URL: '/virtual-cfo-services.php/Section_8_Company_Registration',
		RecommendedURL: '/section-8-company-registration',
	},
	{
		URL: '/virtual-cfo-services.php/bloglist',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/apply-for-pan-online',
		RecommendedURL: '/apply-for-pan-online',
	},
	{
		URL: '/startup-india-registration.php/import/import-export-code-iec-renewal',
		RecommendedURL: '/import-export-code-iec-renewal',
	},
	{
		URL: '/virtual-cfo-services.php/import/privacy-policy',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/import/private-limited-company',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/startup-india-registration.php/import/gst-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/import/contact-us',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/contact-us.php/udyam-registration',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/gst-refund.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/import/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/startup-india-registration.php/dissolution-of-partnership-firm',
		RecommendedURL: '/dissolution-of-partnership-firm',
	},
	{
		URL: '/startup-india-registration.php/rbi-compliance',
		RecommendedURL: '/rbi-compliance',
	},
	{
		URL: '/virtual-cfo-services.php/import/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/startup-india-registration.php/import/about-us',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/virtual-cfo-services.php/import/contact-us',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/private-limited-company.php/dashboard',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/virtual-cfo-services.php/import/private-limited-company-roc-compliance',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/contact-us.php/nri-income-tax-planning',
		RecommendedURL: '/nri-income-tax-filing',
	},
	{
		URL: '/contact-us.php/import-export-code-iec-modification',
		RecommendedURL: '/import-export-code-iec-modification',
	},
	{
		URL: '/contact-us.php/external-commercial-borrowings-ecb-compliance.php',
		RecommendedURL: '/external-commercial-borrowings-ecb-compliance',
	},
	{
		URL: '/virtual-cfo-services.php/import/one-person-company-roc-compliance',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/startup-india-registration.php/import/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/startup-india-registration.php/import/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/startup-india-registration',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/contact-us.php/apply-for-tan-online',
		RecommendedURL: '/apply-for-tan-online',
	},
	{
		URL: '/contact-us.php/import-export-code-iec-registration',
		RecommendedURL: '/import-export-code-iec-registration',
	},
	{
		URL: '/gst-refund.php/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/dashboard',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/startup-india-registration.php/form-15ca-and-15cb',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/llp-roc-compliance.php',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/virtual-cfo-services.php/import-export-code-iec-modification',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/startup-india-registration.php/public-limited-company-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/private-limited-company.php/fdi-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/startup-india-registration.php/trust-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/import/one-person-company-roc-compliance',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/enquiry-success',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/payroll-services',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/gst-registration.php',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/contact-us.php/trust-registration',
		RecommendedURL: '/trust-registration',
	},
	{
		URL: '/startup-india-registration.php/gst-modification',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/apply-for-tan-online',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/sole-proprietorship-registraion',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/closing-one-person-company',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/import/one-person-company-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/about-us',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/gst-refund.php',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/virtual-cfo-services.php/import/one-person-company-registration',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/import/import-export-code-iec-renewal',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/share-transfer-of-a-company.php/partnership-firm-registration',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/dashboard.php/enquiry-success',
		RecommendedURL: '/',
	},
	{
		URL: '/share-transfer-of-a-company.php/udyam-registration',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/startup-india-registration.php/bloglist',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/increase-authorised-share-capital',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/privacy-policy',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/private-limited-company.php/login',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/virtual-cfo-services.php/payroll-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/closing-private-limited-company',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/startup-india-registration.php/nri-income-tax-planning',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/income-tax-returns-filing',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/dashboard',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/virtual-cfo-services.php/trust-registration',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/startup-india-registration.php/import-export-code-iec-modification',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/private-limited-company-roc-compliance',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/startup-india-registration.php/society-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/virtual-cfo-services.php/public-limited-company-registration',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/closing-one-person-company',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/income-tax-returns-filing',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/fssai-renewal',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/din-surrender',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/rbi-compliance',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/din-validation',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/fcgpr-reporting',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/limited-liability-partnership-llp',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/nri-income-tax-planning',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/private-limited-company.php/private-limited-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/virtual-cfo-services.php/tax-deducted-at-source-tds',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/udyam-registration',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/update-din-details',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/gst-refund.php/gst-registration',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-refund.php/virtual-cfo-services',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/share-transfer-of-a-company.php/one-person-company-roc-compliance',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/share-transfer-of-a-company.php/gst-refund',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/import/import-export-code-iec-renewal',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/private-limited-company.php/privacy-policy',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-return-filing.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/share-transfer-of-a-company.php/private-limited-roc-compliance',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/login.php/login',
		RecommendedURL: '/login',
	},
	{
		URL: '/contact-us.php/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/privacy-policy.php/',
		RecommendedURL: '/privacy-policy',
	},
	{
		URL: '/virtual-cfo-services.php/dashboard',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/fdi-compliance',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/login.php/gst-lut-filing',
		RecommendedURL: '/gst-lut-filing',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/import/llp-roc-compliance',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/login.php/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/contact-us.php/',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/virtual-cfo-services.php/llp-roc-compliance',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/appointment-of-auditors',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/index.php',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/',
		RecommendedURL: '/',
	},
	{
		URL: '/external-commercial-borrowings-ecb-compliance.php',
		RecommendedURL: '/external-commercial-borrowings-ecb-compliance',
	},
	{
		URL: '/dashboard.php/login',
		RecommendedURL: '/login',
	},
	{
		URL: '/change-registered-office-address.php/privacy-policy',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/closing-private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/services/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/partnership-firm-registration.php/udyam-registration',
		RecommendedURL: '/partnership-firm-registration',
	},
	{
		URL: '/gst-return-filing.php',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/about-us.php/fdi-compliance',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/index.php/bloglist',
		RecommendedURL: '/',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/import-export-code-iec-modification',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/private-limited-company.php/one-person-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/login.php/foreign-subsidiary-incorporation-registration',
		RecommendedURL: '/login',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/index.php/services/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/index.php/services/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/one-person-company-registration',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/change-registered-office-address.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/change-registered-office-address.php/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/change-registered-office-address.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/change-registered-office-address.php/one-person-company-registration',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/index.php/import/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/index.php/import/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/change-registered-office-address.php/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/change-registered-office-address.php/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/index.php/import/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/limited-liability-partnership-llp.php/llp-roc-compliance.php',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/gst-registration',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/fdi-compliance',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/login.php/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-refund.php/llp-roc-compliance',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/about-us',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/llp-roc-compliance.php/dashboard',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/opc-roc-compliance.php/privacy-policy',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/privacy-policy.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/opc-roc-compliance.php/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/change-registered-office-address.php/about-us',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/limited-liability-partnership-llp.php/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/index.php/services/limited-liability-partnership-llp',
		RecommendedURL: '/limited-liability-partnership-registrationn',
	},
	{
		URL: '/index.php/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/about-us',
		RecommendedURL: '/gst-lut-filing',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/private-limited-roc-compliance',
		RecommendedURL: '/private-limited-roc-filing',
	},
	{
		URL: '/index.php/trust-registration',
		RecommendedURL: '/trust-registration',
	},
	{
		URL: '/index.php/limited-liability-partnership-llp',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/contact-us.php/privacy-policy',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/gst-refund.php/gst-lut-filing',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-refund.php/dashboard',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-refund.php/company-share-transfer',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-refund.php/partnership-firm-registration',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/about-us.php/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/gst-refund.php/privacy-policy',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/about-us.php/privacy-policy',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/index.php/import-export-code-iec-registration',
		RecommendedURL: '/import-export-code-iec-registration',
	},
	{
		URL: '/index.php/company-share-transfer',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/index.php/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/dissolution-of-partnership-firm',
		RecommendedURL: '/dissolution-of-partnership-firm',
	},
	{
		URL: '/index.php/form-15ca-and-15cb',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/index.php/form-15ca-and-15cb',
		RecommendedURL: '/form-15ca-and-15cb-filing',
	},
	{
		URL: '/index.php/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/epf-registration-online',
		RecommendedURL: '/epf-registration-online',
	},
	{
		URL: '/index.php/merger-acquisition-taxation',
		RecommendedURL: '/merger-acquisition-taxation',
	},
	{
		URL: '/index.php/indian-accounting-standards',
		RecommendedURL: '/indian-accounting-standards',
	},
	{
		URL: '/llp-roc-compliance.php/one-person-company-roc-compliance',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/index.php/society-registration',
		RecommendedURL: '/society-registration',
	},
	{
		URL: '/index.php/sole-proprietorship-registraion',
		RecommendedURL: '/sole-proprietorship-registration',
	},
	{
		URL: '/index.php/startup-business-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/limited-liability-partnership-llp.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/contact-us.php/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/llp-roc-compliance.php/dissolution-of-partnership-firm',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/privacy-policy',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/about-us.php/udyam-registration',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/llp-roc-compliance.php/fssai-renewal',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/limited-liability-partnership-llp.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/private-limited-company-roc-compliance',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/llp-roc-compliance.php/closing-one-person-company',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/society-registration',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/apply-for-pan-online',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/apply-for-tan-online',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/private-limited-company.php/society-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/about-us.php/contact-us',
		RecommendedURL: '/',
	},
	{
		URL: '/private-limited-company.php/llp-roc-compliance.php',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-return-filing.php/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/private-limited-company.php/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-return-filing.php/change-registered-office-address',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/private-limited-company.php/moa-aoa-ammendments',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-registration.php/import/login',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/contact-us',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/gst-registration.php/import/private-limited-roc-compliance',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/privacy-policy.php/fdi-compliance',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/gst-registration.php/import/partnership-firm-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/startup-business-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/gst-registration.php/import/fssai-renewal',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/section-8-company-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/bookkeeping-accounting-services',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/nri-income-tax-planning',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/public-limited-company-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/increase-authorised-share-capital',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/society-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/80g-12aa-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/removal-of-director',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/dissolution-of-partnership-firm',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/digital-signature-certificate',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/share-transfer-of-a-company.php/private-limited-company-roc-compliance',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/gst-registration.php/import/indian-accounting-standards',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/appointment-of-auditors',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/gst-lut-filing',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/index.php/privacy-policy',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/services/llp-roc-compliance',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/index.php/services/privacy-policy.php',
		RecommendedURL: '/',
	},
	{
		URL: '/startup-and-business-registration/import/llp-roc-compliance.php',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/index.php/dashboard',
		RecommendedURL: '/',
	},
	{
		URL: '/private-limited-company.php/appointment-of-auditors',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/rbi-compliance-fdi-compliance.php/privacy-policy',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/virtual-cfo-services.php/llp-roc-compliance.php',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/index.php/services/one-person-company-roc-compliance',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/login.php/forgot-password',
		RecommendedURL: '/login',
	},
	{
		URL: '/virtual-cfo-services.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/gst-registration.php/trust-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/closing-limited-liability-partnership-company',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/cma-report-preparation',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/external-commercial-borrowings-ecb-compliance.php',
		RecommendedURL: '/external-commercial-borrowings-ecb-compliance',
	},
	{
		URL: '/gst-registration.php/import/fssai-licence-registeration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/one-person-company-registration',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/private-limited-company.php/import-export-code-iec-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/import/llp-roc-compliance',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/limited-liability-partnership-llp.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-return-filing.php/fdi-compliance',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/gst-registration.php/llp-roc-compliance',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/private-limited-company.php/din-surrender',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/private-limited-company.php/liaison-office-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/private-limited-company',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/share-transfer-of-a-company.php/llp-roc-compliance.php',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/share-transfer-of-a-company.php/gst-lut-filing',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/nri-income-tax-planning',
		RecommendedURL: '/nri-income-tax-filing',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/apply-for-pan-online',
		RecommendedURL: '/apply-for-pan-online',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/liaison-office-registration',
		RecommendedURL: '/liaison-office-registration',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/rbi-compliance',
		RecommendedURL: '/rbi-compliance',
	},
	{
		URL: '/gst-return-filing.php/gst-modification',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/limited-liability-partnership-llp.php/one-person-company-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/limited-liability-partnership-llp.php/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-return-filing.php/trust-registration',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/dashboard.php/society-registration',
		RecommendedURL: '/society-registration',
	},
	{
		URL: '/gst-refund.php/private-limited-company-roc-compliance',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-registration.php/indian-subsidiary-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/apply-for-pan-online',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/merger-acquisition-valuation',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/din-validation',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/form-15ca-and-15cb',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-refund.php/one-person-company-registration',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-registration.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-registration.php/income-tax-returns-filing',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/opc-roc-compliance.php/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/opc-roc-compliance.php/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/section-8-company-registration',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/opc-roc-compliance.php/company-share-transfer',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/gst-registration.php/appointment-of-director',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-registration.php/cancel-surrender-gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/merger-acquisition-taxation',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/public/index.php/apply-for-pan-online',
		RecommendedURL: '/apply-for-pan-online',
	},
	{
		URL: '/gst-registration.php/import/import/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/moa-aoa-ammendments',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-refund.php/llp-roc-compliance.php',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-refund.php/about-us',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-registration.php/import/pf-return-filing',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/change-registered-office-address',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/gst-registration.php/import/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/gst-registration.php/import/income-tax-returns-filing',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/private-limited-company.php/udyam-registration-online',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/llp-roc-compliance.php/import/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/home',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/private-limited-company.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/private-limited-company.php/branch-office-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/private-limited-company.php/closing-one-person-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/partnership-firm-registration.php/llp-roc-compliance.php',
		RecommendedURL: '/partnership-firm-registration',
	},
	{
		URL: '/llp-roc-compliance.php/import/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/partnership-firm-registration.php/one-person-company-registration',
		RecommendedURL: '/partnership-firm-registration',
	},
	{
		URL: '/contact-us.php/digital-signature-certificate',
		RecommendedURL: '/digital-signature-certificate',
	},
	{
		URL: '/letter-of-undertaking-lut-in-gst.php/llp-roc-compliance',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/privacy-policy.php/gst-eway-bill',
		RecommendedURL: '/gst-eway-bill-registration',
	},
	{
		URL: '/opc-roc-compliance.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/contact-us.php/esic-registration-online',
		RecommendedURL: '/esic-registration-online',
	},
	{
		URL: '/gst-return-filing.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/llp-roc-compliance.php/import/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/privacy-policy.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/contact-us.php/merger-acquisition-taxation',
		RecommendedURL: '/merger-acquisition-taxation',
	},
	{
		URL: '/privacy-policy.php/esic-registration-online',
		RecommendedURL: '/esic-registration-online',
	},
	{
		URL: '/contact-us.php/gst-eway-bill',
		RecommendedURL: '/gst-eway-bill-registration',
	},
	{
		URL: '/llp-roc-compliance.php/import/gst-registration',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/import/one-person-company-registration',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/gst-registration.php/udyam-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/opc-roc-compliance.php/one-person-company-roc-compliance',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/contact-us.php/indian-accounting-standards',
		RecommendedURL: '/indian-accounting-standards',
	},
	{
		URL: '/contact-us.php/merger-acquisition-valuation',
		RecommendedURL: '/merger-acquisition-valuation',
	},
	{
		URL: '/contact-us.php/sole-proprietorship-registraion',
		RecommendedURL: '/sole-proprietorship-registration',
	},
	{
		URL: '/startup-india-registration.php',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/llp-roc-compliance.php/import/about-us',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/contact-us.php/startup-business-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/index.php/import/private-limited-company-roc-compliance',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/llp-roc-compliance.php/import/limited-liability-partnership-registration',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/login.php/udyam-registration',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/llp-roc-compliance.php/fcgpr-reporting',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/gst-registration.php/esic-registration-online',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/public/index.php/appointment-of-auditors',
		RecommendedURL: '/appointment-of-auditors',
	},
	{
		URL: '/gst-registration.php/import/change-in-partners',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/private-limited-company.php/public-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/import/privacy-policy',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/contact-us.php/llp-roc-compliance',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/llp-roc-compliance.php/esi-return-filing',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/dashboard.php/bloglist',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/services/private-limited-company.php',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/virtual-cfo-services.php',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/index.php/gst-refund.php',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/index.php/import/one-person-company-registration',
		RecommendedURL: '/appointment-of-auditorss',
	},
	{
		URL: '/index.php/import/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/privacy-policy.php/import/import-export-code-iec-renewal',
		RecommendedURL: '/import-export-code-iec-renewal',
	},
	{
		URL: '/privacy-policy.php/form-15ca-and-15cb',
		RecommendedURL: '/form-15ca-and-15cb-filing',
	},
	{
		URL: '/index.php/import/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/privacy-policy.php/sole-proprietorship-registraion',
		RecommendedURL: '/sole-proprietorship-registration',
	},
	{
		URL: '/privacy-policy.php/fcra-registration',
		RecommendedURL: '/fcra-registration',
	},
	{
		URL: '/privacy-policy.php/bloglist',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/import/limited-liability-partnership-llp',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/dashboard.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/llp-roc-compliance.php',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/privacy-policy.php/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/privacy-policy.php/about-us',
		RecommendedURL: '/',
	},
	{
		URL: '/login.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/dashboard.php/fdi-compliance',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/dashboard.php/udyam-registration',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/index.php/digital-signature-certificate',
		RecommendedURL: '/digital-signature-certificate',
	},
	{
		URL: '/contact-us.php/dashboard',
		RecommendedURL: '',
	},
	{
		URL: '/contact-us.php/fdi-compliance',
		RecommendedURL: '/fdi-compliance',
	},
	{
		URL: '/index.php/services/about-us',
		RecommendedURL: '/',
	},
	{
		URL: '/about-us.php/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/about-us.php/import/about-us',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/opc-roc-compliance.php',
		RecommendedURL: '/one-person-company-roc-compliancee',
	},
	{
		URL: '/about-us.php/import/contact-us',
		RecommendedURL: '/',
	},
	{
		URL: '/about-us.php/import/privacy-policy',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/letter-of-undertaking-lut-in-gst.php',
		RecommendedURL: '/gst-lut-filing',
	},
	{
		URL: '/dashboard.php/',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/import/import-export-code-iec-renewal',
		RecommendedURL: '/import-export-code-iec-renewal',
	},
	{
		URL: '/contact-us.php/bloglist',
		RecommendedURL: '',
	},
	{
		URL: '/contact-us.php/about-us',
		RecommendedURL: '/',
	},
	{
		URL: '/about-us.php/startup-india-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/about-us.php/sole-proprietorship-registraion',
		RecommendedURL: '/sole-proprietorship-registration',
	},
	{
		URL: '/index.php/esic-registration-online',
		RecommendedURL: '/esic-registration-online',
	},
	{
		URL: '/contact-us.php/company-share-transfer',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/privacy-policy.php/indian-accounting-standards',
		RecommendedURL: '/indian-accounting-standards',
	},
	{
		URL: '/index.php/fcra-registration',
		RecommendedURL: '/apply-for-tan-online',
	},
	{
		URL: '/dashboard.php/esic-registration-online',
		RecommendedURL: '/esic-registration-online',
	},
	{
		URL: '/dashboard.php/trust-registration',
		RecommendedURL: '/trust-registration',
	},
	{
		URL: '/limited-liability-partnership-llp.php/startup-business-registration',
		RecommendedURL: '/startup-india-registration',
	},
	{
		URL: '/public/index.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/about-us.php/bloglist',
		RecommendedURL: '/',
	},
	{
		URL: '/about-us.php/society-registration',
		RecommendedURL: '/society-registration',
	},
	{
		URL: '/dashboard.php/form-15ca-and-15cb',
		RecommendedURL: '/form-15ca-and-15cb-filing',
	},
	{
		URL: '/about-us.php/blogs',
		RecommendedURL: '/',
	},
	{
		URL: '/about-us.php/esic-registration-online',
		RecommendedURL: '/esic-registration-online',
	},
	{
		URL: '/index.php/fcra-registration',
		RecommendedURL: '/fcra-registration',
	},
	{
		URL: '/about-us.php/form-15ca-and-15cb',
		RecommendedURL: '/form-15ca-and-15cb-filing',
	},
	{
		URL: '/contact-us.php/epf-registration-online',
		RecommendedURL: '/epf-registration-online',
	},
	{
		URL: '/dashboard.php/fssai-renewal',
		RecommendedURL: '/fssai-renewal',
	},
	{
		URL: '/privacy-policy.php/udyam-registration',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/contact-us.php/login',
		RecommendedURL: '',
	},
	{
		URL: '/import/import-export-code-iec-renewal',
		RecommendedURL: '/import-export-code-iec-renewal',
	},
	{
		URL: '/index.php/appointment-of-auditors',
		RecommendedURL: '/appointment-of-auditors',
	},
	{
		URL: '/index.php/gst-refund',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/login.php/dashboard',
		RecommendedURL: '/login',
	},
	{
		URL: '/index.php/income-tax-returns-filing',
		RecommendedURL: '/income-tax-returns-filing',
	},
	{
		URL: '/privacy-policy.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/private-limited-company.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/index.php/payroll-services',
		RecommendedURL: '/payroll-services',
	},
	{
		URL: '/index.php/branch-office-registration',
		RecommendedURL: '/branch-office-registration',
	},
	{
		URL: '/index.php/bookkeeping-accounting-services',
		RecommendedURL: '/bookkeeping-accounting-services',
	},
	{
		URL: '/contact-us.php/appointment-of-auditors',
		RecommendedURL: '/appointment-of-auditors',
	},
	{
		URL: '/index.php/udyam-registration-online',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/index.php/gst-annual-return',
		RecommendedURL: '/gst-annual-return',
	},
	{
		URL: '/index.php/company-name-change',
		RecommendedURL: '/company-name-change',
	},
	{
		URL: '/contact-us.php/public-limited-company-registration',
		RecommendedURL: '/public-limited-company-registration',
	},
	{
		URL: '/login.php/closing-private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/.php/esi-return-filing',
		RecommendedURL: '/esi-return-filing',
	},
	{
		URL: '/contact-us.php/income-tax-returns-filing',
		RecommendedURL: '/income-tax-returns-filing',
	},
	{
		URL: '/index.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/dashboard.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/private-limited-company.php/private-limited-company-registration',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/fssai-licence-registeration',
		RecommendedURL: '/fssai-licence-registeration',
	},
	{
		URL: '/contact-us.php/update-din-details',
		RecommendedURL: '/update-din-details',
	},
	{
		URL: '/privacy-policy.php/contact-us',
		RecommendedURL: '',
	},
	{
		URL: '/index.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/about-us.php/bookkeeping-accounting-services',
		RecommendedURL: '/bookkeeping-accounting-services',
	},
	{
		URL: '/index.php/din-validation',
		RecommendedURL: '/din-validation',
	},
	{
		URL: '/index.php/appointment-of-director',
		RecommendedURL: '/appointment-of-director',
	},
	{
		URL: '/index.php/moa-aoa-ammendments',
		RecommendedURL: '/moa-aoa-ammendments',
	},
	{
		URL: '/index.php/cancel-surrender-gst-registration',
		RecommendedURL: '/cancel-surrender-gst-registration',
	},
	{
		URL: '/index.php/rbi-compliance',
		RecommendedURL: '/rbi-compliance',
	},
	{
		URL: '/index.php/80g-12aa-registration',
		RecommendedURL: '/80g-12aa-registration',
	},
	{
		URL: '/index.php/merger-acquisition-valuation',
		RecommendedURL: '/merger-acquisition-valuation',
	},
	{
		URL: '/privacy-policy.php/change-registered-office-address',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/index.php/producer-company-registration',
		RecommendedURL: '/producer-company-registration',
	},
	{
		URL: '/contact-us.php/limited-liability-partnership-registration',
		RecommendedURL: '/limited-liability-partnership-registration',
	},
	{
		URL: '/index.php/din-surrender',
		RecommendedURL: '/din-surrender',
	},
	{
		URL: '/index.php/fssai-renewal',
		RecommendedURL: '/fssai-renewal',
	},
	{
		URL: '/index.php/change-in-partners',
		RecommendedURL: '/change-in-partners',
	},
	{
		URL: '/index.php/gst-modification',
		RecommendedURL: '/gst-modification',
	},
	{
		URL: '/index.php/public-limited-company-registration',
		RecommendedURL: '/public-limited-company-registration',
	},
	{
		URL: '/privacy-policy.php/one-person-company-roc-compliance',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/index.php/import/one-person-company-roc-compliance',
		RecommendedURL: '/one-person-company-roc-compliance',
	},
	{
		URL: '/index.php/closing-private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/contact-us.php/80g-12aa-registration',
		RecommendedURL: '/80g-12aa-registration',
	},
	{
		URL: '/index.php/import-export-code-iec-modification',
		RecommendedURL: '/import-export-code-iec-modification',
	},
	{
		URL: '/privacy-policy.php/partnership-firm-registration',
		RecommendedURL: '',
	},
	{
		URL: '/index.php/tax-deducted-at-source-tds',
		RecommendedURL: '/tax-deducted-at-source-tds',
	},
	{
		URL: '/contact-us.php/payroll-services',
		RecommendedURL: '/payroll-services',
	},
	{
		URL: '/contact-us.php/indian-subsidiary-registration',
		RecommendedURL: '/indian-subsidiary-registration',
	},
	{
		URL: '/dashboard.php/bookkeeping-accounting-services',
		RecommendedURL: '/bookkeeping-accounting-services',
	},
	{
		URL: '/index.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/closing-limited-liability-partnership-company',
	},
	{
		URL: '/login.php/gst-refund',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/index.php/blogs',
		RecommendedURL: '/blogs',
	},
	{
		URL: '/index.php/services/gst-refund',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/dashboard.php/gst-annual-return',
		RecommendedURL: '/gst-annual-return',
	},
	{
		URL: '/contact-us.php/removal-of-director',
		RecommendedURL: '/removal-and-resignation-of-director',
	},
	{
		URL: '/privacy-policy.php/closing-one-person-company',
		RecommendedURL: '/closing-one-person-company',
	},
	{
		URL: '/index.php/section-8-company-registration',
		RecommendedURL: '/section-8-company-registration',
	},
	{
		URL: '/login.php/virtual-cfo-services',
		RecommendedURL: '/virtual-cfo-services',
	},
	{
		URL: '/privacy-policy.php/section-8-company-registration',
		RecommendedURL: '/section-8-company-registration',
	},
	{
		URL: '/privacy-policy.php/import-export-code-iec-modification',
		RecommendedURL: '/import-export-code-iec-modification',
	},
	{
		URL: '/dashboard.php/business-plan-preparation',
		RecommendedURL: '/business-plan-preparation',
	},
	{
		URL: '/index.php/llp-roc-compliance',
		RecommendedURL: '/llp-roc-compliance',
	},
	{
		URL: '/dashboard.php/esi-return-filing',
		RecommendedURL: '/esi-return-filing',
	},
	{
		URL: '/dashboard.php/blogs',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/index.php/fcgpr-reporting',
		RecommendedURL: '/fcgpr-reporting',
	},
	{
		URL: '/contact-us.php/llp-name-change',
		RecommendedURL: '/llp-name-change',
	},
	{
		URL: '/dashboard.php/liaison-office-registration',
		RecommendedURL: '/liaison-office-registration',
	},
	{
		URL: '/contact-us.php/section-8-company-registration',
		RecommendedURL: '/section-8-company-registration',
	},
	{
		URL: '/contact-us.php/fssai-renewal',
		RecommendedURL: '/fssai-renewal',
	},
	{
		URL: '/privacy-policy.php/fcgpr-reporting',
		RecommendedURL: '/fcgpr-reporting',
	},
	{
		URL: '/privacy-policy.php/closing-private-limited-company',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/contact-us.php/producer-company-registration',
		RecommendedURL: '/producer-company-registration',
	},
	{
		URL: '/contact-us.php/liaison-office-registration',
		RecommendedURL: '/liaison-office-registration',
	},
	{
		URL: '/dashboard.php/cancel-surrender-gst-registration',
		RecommendedURL: '/cancel-surrender-gst-registration',
	},
	{
		URL: '/contact-us.php/closing-one-person-company',
		RecommendedURL: '/closing-one-person-company',
	},
	{
		URL: '/contact-us.php/cma-report-preparation',
		RecommendedURL: '/cma-report-preparation',
	},
	{
		URL: '/contact-us.php/gst-lut-filing',
		RecommendedURL: '/gst-lut-filing',
	},
	{
		URL: '/privacy-policy.php/dashboard',
		RecommendedURL: '/',
	},
	{
		URL: '/contact-us.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/closing-limited-liability-partnership-company',
	},
	{
		URL: '/contact-us.php/increase-authorised-share-capital',
		RecommendedURL: '/increase-authorised-share-capital',
	},
	{
		URL: '/contact-us.php/bookkeeping-accounting-services',
		RecommendedURL: '/bookkeeping-accounting-services',
	},
	{
		URL: '/login.php/fcgpr-reporting',
		RecommendedURL: '/fcgpr-reporting',
	},
	{
		URL: '/contact-us.php/gst-annual-return',
		RecommendedURL: '/gst-annual-return',
	},
	{
		URL: '/contact-us.php/cancel-surrender-gst-registration',
		RecommendedURL: '/cancel-surrender-gst-registration',
	},
	{
		URL: '/login.php/',
		RecommendedURL: '/login',
	},
	{
		URL: '/login.php/fssai-licence-registeration',
		RecommendedURL: '/fssai-licence-registeration',
	},
	{
		URL: '/index.php/services/gst-lut-filing',
		RecommendedURL: '/gst-lut-filing',
	},
	{
		URL: '/contact-us.php/udyam-registration-online',
		RecommendedURL: '/udyam-registration-online',
	},
	{
		URL: '/dashboard.php/nri-income-tax-planning',
		RecommendedURL: '/nri-income-tax-filing',
	},
	{
		URL: '/contact-us.php/branch-office-registration',
		RecommendedURL: '/branch-office-registration',
	},
	{
		URL: '/contact-us.php/dissolution-of-partnership-firm',
		RecommendedURL: '/dissolution-of-partnership-firm',
	},
	{
		URL: '/privacy-policy.php/company-name-change',
		RecommendedURL: '/company-name-change',
	},
	{
		URL: '/contact-us.php/fcgpr-reporting',
		RecommendedURL: '/fcgpr-reporting',
	},
	{
		URL: '/dashboard.php/dissolution-of-partnership-firm',
		RecommendedURL: '/dissolution-of-partnership-firm',
	},
	{
		URL: '/contact-us.php/company-name-change',
		RecommendedURL: '/company-name-change',
	},
	{
		URL: '/contact-us.php/closing-private-limited-company',
		RecommendedURL: '/closing-private-limited-company',
	},
	{
		URL: '/contact-us.php/appointment-of-director',
		RecommendedURL: '/appointment-of-director',
	},
	{
		URL: '/privacy-policy.php/tax-deducted-at-source-tds',
		RecommendedURL: '/tds-filing',
	},
	{
		URL: '/gst-return-filing.php/contact-us',
		RecommendedURL: '',
	},
	{
		URL: '/contact-us.php/one-person-company-registration',
		RecommendedURL: '/one-person-company-registration',
	},
	{
		URL: '/contact-us.php/fssai-licence-registeration',
		RecommendedURL: '/fssai-licence-registeration',
	},
	{
		URL: '/privacy-policy.php/apply-for-pan-online',
		RecommendedURL: '/apply-for-pan-online',
	},
	{
		URL: '/contact-us.php/change-in-partners',
		RecommendedURL: '/change-in-partners',
	},
	{
		URL: '/contact-us.php/business-plan-preparation',
		RecommendedURL: '/business-plan-preparation',
	},
	{
		URL: '/privacy-policy.php/appointment-of-auditors',
		RecommendedURL: '/appointment-of-auditors',
	},
	{
		URL: '/about-us.php/dashboard',
		RecommendedURL: '/',
	},
	{
		URL: '/index.php/gst-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/privacy-policy.php/esi-return-filing',
		RecommendedURL: '/esi-return-filing',
	},
	{
		URL: '/gst-registration.php/partnership-firm-registration',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/dashboard.php/din-validation',
		RecommendedURL: '/din-validation',
	},
	{
		URL: '/contact-us.php/gst-modification',
		RecommendedURL: '/gst-modification',
	},
	{
		URL: '/index.php/apply-for-tan-online',
		RecommendedURL: '/apply-for-tan-online',
	},
	{
		URL: '/contact-us.php/moa-aoa-ammendments',
		RecommendedURL: '/moa-aoa-ammendments',
	},
	{
		URL: '/privacy-policy.php/merger-acquisition-valuation',
		RecommendedURL: '/merger-acquisition-valuation',
	},
	{
		URL: '/gst-refund.php/private-limited-roc-compliance',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/about-us.php/change-in-partners',
		RecommendedURL: '/change-in-partners',
	},
	{
		URL: '/gst-refund.php/change-registered-office-address',
		RecommendedURL: '/gst-refund',
	},
	{
		URL: '/gst-registration.php/company-share-transfer',
		RecommendedURL: '/gst-registration',
	},
	{
		URL: '/privacy-policy.php/update-din-details',
		RecommendedURL: '/update-din-details',
	},
	{
		URL: '/contact-us.php/pf-return-filing',
		RecommendedURL: '/pf-return-filing',
	},
	{
		URL: '/dashboard.php/cma-report-preparation',
		RecommendedURL: '/cma-report-preparation',
	},
	{
		URL: '/dashboard.php/fcgpr-reporting',
		RecommendedURL: '/fcgpr-reporting',
	},
	{
		URL: '/privacy-policy.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/closing-limited-liability-partnership-company',
	},
	{
		URL: '/about-us.php/one-person-company-registration',
		RecommendedURL: '/one-person-company-registration',
	},
	{
		URL: '/share-transfer-of-a-company.php/company-share-transfer',
		RecommendedURL: '/company-share-transfer',
	},
	{
		URL: '/privacy-policy.php/bookkeeping-accounting-services',
		RecommendedURL: '/bookkeeping-accounting-services',
	},
	{
		URL: '/privacy-policy.php/gst-modification',
		RecommendedURL: '/gst-modification',
	},
	{
		URL: '/contact-us.php/tax-deducted-at-source-tds',
		RecommendedURL: '/tax-deducted-at-source-tds',
	},
	{
		URL: '/gst-registration.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/privacy-policy.php/cma-report-preparation',
		RecommendedURL: '/cma-report-preparation',
	},
	{
		URL: '/private-limited-company.php/gst-return-filing',
		RecommendedURL: '/private-limited-company-registration',
	},
	{
		URL: '/privacy-policy.php/liaison-office-registration',
		RecommendedURL: '/liaison-office-registration',
	},
	{
		URL: '/dashboard.php/appointment-of-director',
		RecommendedURL: '/appointment-of-director',
	},
	{
		URL: '/contact-us.php/foreign-subsidiary-incorporation-registration',
		RecommendedURL: '/foreign-subsidiary-incorporation-registration',
	},
	{
		URL: '/contact-us.php/change-registered-office-address',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/privacy-policy.php/moa-aoa-ammendments',
		RecommendedURL: '/moa-aoa-ammendments',
	},
	{
		URL: '/dashboard.php/one-person-company-registration',
		RecommendedURL: '/appointment-of-auditors',
	},
	{
		URL: '/dashboard.php/closing-limited-liability-partnership-company',
		RecommendedURL: '/closing-limited-liability-partnership-company',
	},
	{
		URL: '/dashboard.php/contact-us',
		RecommendedURL: '/contact-us',
	},
	{
		URL: '/llp-roc-compliance.php/gst-return-filing',
		RecommendedURL: '/gst-return-filing',
	},
	{
		URL: '/index.php/services/change-registered-office-address',
		RecommendedURL: '/change-registered-office-address',
	},
	{
		URL: '/index.php/private-limited-roc-compliance',
		RecommendedURL: '/private-limited-roc-filing',
	},
	{
		URL: '/dashboard.php/rbi-compliance',
		RecommendedURL: '/rbi-compliance',
	},
];
