import axios from 'axios';

export const baseUrl = "https://admin.eauditoroffice.com/api"		
// export const baseUrl = process.env.REACT_APP_LIVE_BASE_URL;
// export const baseUrl = process.env.REACT_APP_BASEURL;
// export const baseUrl = 'http://192.168.0.126:8081/api';

export var name_re = /^[a-zA-Z .]+[a-zA-Z .]+[a-zA-Z .]+[a-zA-Z .]$/;
export var mobile_re = /[6-9]{1}[0-9]{9}/;
export var email_re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export var ContactInitialState = {
	EnqName: '',
	EnqEmail: '',
	EnqMobile: '',
	EnqMessage: '',
};

export var serviceInitial = {
	name: '',
	mobile: '',
	email: '',
	url: '',
};

export var incomeTaxInitial = {
	file: {},
	url: '',
	name: '',
	mobile: '',
	email: '',
};
export var incomeTaxInitialFile = {
	file: {},
};

export var nlEmail = {
	email: '',
};

export var initialContent = {
	one: 'private-limited-company-registration',
	two: 'limited-liability-partnership-registration',
	three: 'one-person-company-registration',
};

export var secondContent = {
	one: 'gst-registration',
	two: 'gst-return-filing',
	three: 'gst-modification',
};

export var thirdContent = {
	one: 'private-limited-roc-filing',
	two: 'one-person-company-roc-compliance',
	three: 'llp-roc-compliance',
};

export var baseVar = window.location.origin;

export const AuthAxios = axios.create({
	baseURL: baseUrl,
});

// blogBaseCommonUrl

export let CategoriesBase = '/categoryList?limit=5';

export let recentArticlesBase = '/blogRecentArticles';
